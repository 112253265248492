import { navigate } from 'gatsby'
import { I18nContext, localizePath } from 'gatsby-plugin-i18n'
import { useContext } from 'react'
import FooterSecondary from 'swp-components/lib/components/footer-secondary/footer'
import NavBar from 'swp-components/lib/components/navbar/navbar'
import Newrelic from '@/components/newrelic-agent'

import strapi from '@/strapi'

const CitrinePage = ({ serverData }) => {
  let [citrineBrandHeaders, citrineFooters] = strapi.parseContents(
    serverData.contents
  )
  let { locale, defaultLocale, originalPath } = useContext(I18nContext)

  const onChangeLocale = (action, payload) => {
    if (action === 'change-locale') {
      navigate(localizePath(originalPath, payload.locale, defaultLocale))
    }
  }
  return (
    <>
      <Newrelic />
      <NavBar menu={citrineBrandHeaders} onAction={onChangeLocale} />
      <div style={{ height: '200px' }}></div>
      <FooterSecondary items={citrineFooters} />
    </>
  )
}

export default CitrinePage

export const getServerData = async ({ pageContext }) => {
  let contents = await strapi.getContents(
    [`citrine-brand-headers`, `citrine-footers`],
    pageContext.locale
  )
  return {
    props: { contents, locale: pageContext.locale },
  }
}
